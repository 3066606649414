import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AppService } from "../../app.service";
import { PropertiesService } from "src/app/services/properties.service";

@Component({
  selector: "app-home-search",
  templateUrl: "./home-search.component.html",
  styleUrls: ["./home-search.component.scss"],
})
export class HomeSearchComponent implements OnInit {
  @Input() variant: number = 1;
  @Input() vertical: boolean = false;
  @Input() searchOnBtnClick: boolean = false;
  @Input() project:boolean = true;

  @Input() removedSearchField: string;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();
  public showMore: boolean = false;
  public form: UntypedFormGroup;
  public propertyTypes = [];
  public propertyStatuses = [];
  public countries = [];
  public states = [];
  public cities = [];
  public zones = [];
  public neighborhoods = [];
  public streets = [];
  public features = [];
  public OptionsFilters: any = [];
  constructor(
    public appService: AppService,
    public propertiesService: PropertiesService,
    public fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.vertical) {
      this.showMore = true;
    }
    this.propertyTypes = this.appService.getPropertyTypes();
    this.propertyStatuses = this.appService.getPropertyStatuses();
    // this.cities = this.appService.getCities();
    // this.neighborhoods = this.appService.getNeighborhoods();
    this.streets = this.appService.getStreets();
    this.features = this.appService.getFeatures();
    this.form = this.fb.group({
      codePortal: null,
      propertyType: null,
      propertyStatus: null,
      price: this.fb.group({
        from: null,
        to: null,
      }),
      country: null,
      state: null,
      city: null,
      zone: null,
      neighborhood: null,
      street: null,
      bedrooms: this.fb.group({
        from: null,
        to: null,
      }),
      bathrooms: this.fb.group({
        from: null,
        to: null,
      }),
      garages: this.fb.group({
        from: null,
        to: null,
      }),
      area: this.fb.group({
        from: null,
        to: null,
      }),
      yearBuilt: this.fb.group({
        from: null,
        to: null,
      }),
      features: this.buildFeatures(),
    });

    this.onSearchChange.emit(this.form);
    this.getOptionsSelectAPI();
  }

  getOptionsSelectAPI() {
    this.propertiesService.getOptionsSelect().subscribe((data) => {
      this.OptionsFilters = data.data;
      this.propertyTypes = data.data.property_type;
      this.propertyStatuses = data.data.business_type;
      this.countries = data.data.country;
      this.selectCountryDefault();
    });
  }
  public selectCountryDefault() {

  }
  public buildFeatures() {
    const arr = this.features.map((feature) => {
      return this.fb.group({
        id: feature.id,
        name: feature.name,
        selected: feature.selected,
      });
    });
    return this.fb.array(arr);
  }

  ngOnChanges() {
    if (this.removedSearchField) {
      if (this.removedSearchField.indexOf(".") > -1) {
        let arr = this.removedSearchField.split(".");
        this.form.controls[arr[0]]["controls"][arr[1]].reset();
      } else if (this.removedSearchField.indexOf(",") > -1) {
        let arr = this.removedSearchField.split(",");
        this.form.controls[arr[0]]["controls"][arr[1]]["controls"][
          "selected"
        ].setValue(false);
      } else {
        this.form.controls[this.removedSearchField].reset();
      }
    }
  }

  public reset() {
    this.form.reset({
      codePortal: null,
      propertyType: null,
      propertyStatus: null,
      price: {
        from: null,
        to: null,
      },
      city: null,
      country: null,
      state: null,
      zone: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: {
        from: null,
        to: null,
      },
      bathrooms: {
        from: null,
        to: null,
      },
      garages: {
        from: null,
        to: null,
      },
      area: {
        from: null,
        to: null,
      },
      yearBuilt: {
        from: null,
        to: null,
      },
      features: this.features,
    });
    this.selectCountryDefault();
  }

  public search() {
    this.onSearchClick.emit();
  }

  public onSelectCountry() {
    let selectPosition = { ...this.form.controls["country"].getRawValue() };
    this.propertiesService
      .getOptionsUbication("states", selectPosition.id)
      .subscribe((data) => {
        this.states = data.data.estados;
      });
  }

  public onSelectState() {
    let selectPosition = { ...this.form.controls["state"].getRawValue() };
    this.propertiesService
      .getOptionsUbication("ciudades", selectPosition.id)
      .subscribe((data) => {
        this.cities = data.data.ciudades;
      });
  }

  public onSelectCity() {
    let selectPosition = { ...this.form.controls["city"].getRawValue() };
    this.propertiesService
      .getOptionsUbication("zona", selectPosition.id)
      .subscribe((data) => {
        this.zones = data.data.zonas;
      });
  }

  // public onSelectCity(){
  //   this.form.controls['neighborhood'].setValue(null, {emitEvent: false});
  //   this.form.controls['street'].setValue(null, {emitEvent: false});
  // }
  public onSelectZone() {
    let selectPosition = { ...this.form.controls["zone"].getRawValue() };
    this.propertiesService
      .getOptionsUbication("barrio", selectPosition.id)
      .subscribe((data) => {
        this.neighborhoods = data.data.barrios;
      });
  }
  // public onSelectNeighborhood(){
  //   this.form.controls['street'].setValue(null, {emitEvent: false});
  // }

  public getAppearance() {
    return this.variant != 3 ? "outline" : "";
  }
  public getFloatLabel() {
    return this.variant == 1 ? "always" : "";
  }
}
